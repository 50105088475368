import React, { useContext, useEffect, useState } from 'react';
import { IconButton, MenuItem, Select, Stack, TextField, Typography, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import appContext from 'src/Context/AppContext';
import { LoadingButton } from '@mui/lab';
import { getQuestionStreams, updateCurrentUser } from 'src/service/service';
import { toast } from 'react-toastify';
import { RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserType({ open, setOpen }) {
    const [selectedType, setSelectedType] = useState(null);
    const AppContext = useContext(appContext);


    const onSubmit = async () => {
        if (!selectedType) {
            setSelectedType(false)
            return;
        }
        AppContext?.setLoading(true)
        try {
            let res = await updateCurrentUser({ user_type: selectedType });
            AppContext?.callMe()
            AppContext?.setIsLoggedIn(true);
            setOpen(false)
            AppContext?.setLoading(false)
        } catch (e) {
            console.error(e?.response?.data?.message);
            toast.error(e?.response?.data?.message);
            AppContext?.setLoading(false)
        }
    };


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent style={{ textAlign: "center", padding: 30 }} >
                <Typography variant='h5' mb={3}>{`Hi, ${AppContext?.user?.name || ''}`}</Typography>
                <DialogContentText id="alert-dialog-slide-description" color={'dark'} >
                    Please select a user type
                </DialogContentText>
                <Box my={3}>
                    <RadioGroup
                        row
                        onChange={(e) => {
                            setSelectedType(e.target.value);
                        }}
                    >
                        <FormControlLabel
                            value="job seeker"
                            control={<Radio />}
                            label="Job Seeker"
                        />
                        <FormControlLabel
                            value="interviewer"
                            control={<Radio />}
                            label="Interviewer"
                        />
                    </RadioGroup>
                    {selectedType === false && <p className="text-danger " style={{ fontSize: 12 }}>User Type  is required.</p>}
                </Box>
                <LoadingButton onClick={onSubmit} sx={{ my: 1 }}
                    fullWidth
                    size="large"
                    variant="contained"
                    color="inherit"
                >Save
                </LoadingButton>
            </DialogContent>

        </Dialog>
    );
}
