import React, { useContext } from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import ContactImg from "../../assets/img/Contact us-rafiki.png"
import { LoadingButton } from "@mui/lab";
import { Box, Card, Stack, TextField, Typography } from "@mui/material";
import appContext from "src/Context/AppContext";
import { useForm } from "react-hook-form";
import { newFeedback } from "src/service/service";
import "../../vk.css";

export default function Contact() {
  const AppContext = useContext(appContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      let res = await newFeedback(data);
      toast.success("Message sent successfully");
      reset();
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
  };
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container flexCenter contact-container">
          <div className=" flexNullCenter x100 contact-card">
            <div className="leftDiv">
              <HeaderInfo>
                <h1 className="font40 extraBold">Let's get in touch</h1>
                <Typography variant="body2">
                  *Please fill the below form for get in touch with us.
                </Typography>
              </HeaderInfo>
              <div style={{ alignItems: "center" }}>
                <div
                  style={{ padding: "0px" }}
                  className="col-xs-12 col-sm-12  "
                >
                  <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ margin: "20px 0 0 0px" }}
                  >
                    <Stack paddingX={1} paddingY={2}>
                      <TextField
                        variant="outlined"
                        name="name"
                        label="Name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      {errors.name && errors.name.type === "required" && (
                        <p className="text-danger " style={{ fontSize: 12 }}>
                          Name is required.
                        </p>
                      )}
                    </Stack>
                    <Stack paddingX={1} paddingY={2}>
                      <TextField
                        variant="outlined"
                        name="email"
                        label="Email address"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <p className="text-danger " style={{ fontSize: 12 }}>
                          Email is required.
                        </p>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <p className="text-danger " style={{ fontSize: 12 }}>
                          Invalid email
                        </p>
                      )}
                    </Stack>
                    <Stack paddingX={1} paddingY={2}>
                      <TextField
                        variant="outlined"
                        name="question"
                        label="Question or Feedback"
                        {...register("question", {
                          required: true,
                        })}
                      />
                      {errors.question && errors.question.type === "required" && (
                        <p className="text-danger " style={{ fontSize: 12 }}>
                          Question or Feedback is required.
                        </p>
                      )}
                    </Stack>

                    <Stack px={1} pt={3}>
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                      // color="inherit"
                      >
                        Send Message
                      </LoadingButton>
                    </Stack>
                  </form>
                </div>
              </div>
            </div>
            <div className="rightDiv"><img src={ContactImg} /></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 0px 0 0px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
