import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";

import Nav from "./nav";
import Main from "./main";
import Header from "./header";
import { useResponsive } from "src/hooks/use-responsive";
import UserType from "src/components/UserType";
import appContext from "src/Context/AppContext";

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [open, setOpen] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const lgUp = useResponsive("up", "lg");
  const AppContext = useContext(appContext);

  console.log("AppContext?.user?.user_type", AppContext?.user?.user_type);

  useEffect(() => {
    if (AppContext?.user?.data?.user_type) setOpen(true);
    else setOpen(false);
  }, [AppContext?.user]);

  return (
    <>
      {!lgUp && <Header onOpenNav={() => setOpenNav(true)} />}

      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Main>{children}</Main>
      </Box>

      {open && <UserType open={open} setOpen={setOpen} />}
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
