import React from "react";
import styled from "styled-components";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { LoadingButton } from "@mui/lab";
import { useRouter } from "src/routes/hooks";

export default function Header() {
  const router = useRouter();
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h2 className="extraBold ssss font60">Crush Your Next Interview!</h2>
          <HeaderP className="font14 semiBold">
            Perfect your interview skills with real questions and instant, unbiased feedback from your personal AI Interview Coach.</HeaderP>
          <BtnWrapper>
            <LoadingButton variant="contained" onClick={() => router.push('/register')}>Try Capstone Interviewer</LoadingButton>
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <VideoContainer>
            <Video
              className="radius8"
              autoPlay
              muted
              loop
              playsInline
            >
              <source src="https://capstone-interview.s3.ap-south-1.amazonaws.com/1737120405480-capstone-interview.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </VideoContainer>
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Practice before your interview and track your progress over time.</em>
              </p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 750px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const BtnWrapper = styled.div`
  max-width: 210px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const GreyDiv = styled.div`
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  width: 100%;
  margin-right: -5%;
  @media (max-width: 960px) {
    justify-content: center;
    margin-right: 0;
  }
`;

const VideoContainer = styled.div`
  width: 95%;
  max-width: 750px;
  position: relative;
  @media (max-width: 960px) {
    width: 85%;
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media (max-width: 853px) {
    margin-top: 5%;
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  left: -40px;
  bottom: -80px; // Moved down to avoid overlap
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media (max-width: 1200px) {
    left: 0;
    bottom: -60px;
  }
  @media (max-width: 960px) {
    left: 50%;
    transform: translateX(-50%);
    bottom: -80px;
  }
  @media (max-width: 560px) {
    width: 90%;
    max-width: none;
  }
`;

const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;

const DotsWrapper = styled.div`
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  @media (max-width: 1280px) {
    right: -30px;
  }
  @media (max-width: 960px) {
    right: 0;
    top: 20px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;