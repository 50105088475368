import axios from 'axios';

export const axios_instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 1000000,
});

export async function authorizeMe(token) {
  axios_instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export async function me() {
  return new Promise((resolve, reject) => {
    axios_instance
      .get('v1/user')
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function signin(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/auth/login', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function signup(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/auth/register', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function forgotpassword(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/auth/forgot-password', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function newFeedback(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/helps', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function resetpassword(data, token) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post(`v1/auth/reset-password?token=${token}`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function googleAuth(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/auth/google', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function updateCurrentUser(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .patch('v1/user', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// question
export async function getQuestionAnswerList(page, limit, filter) {
  let query = `?page=${page}&limit=${limit}&sortBy=createdAt:desc&`;
  if (filter?.stream) query = query + `stream=${filter?.stream}&`;
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/question-answer${query}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export async function getQuestionAnswerByID(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/question-answer/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function updateQuestionAnswer(id, data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .patch(`v1/question-answer/${id}`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export async function deleteQuestion(id, data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .delete(`v1/question-answer/${id}`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export async function addQuestionAnswer(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post(`v1/question-answer`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
// interview
export async function getInterviewList(page, limit, filterName) {
  let query = `?page=${page}&limit=${limit}&sortBy=createdAt:desc`;
  let withsearchquery = `?page=${page}&limit=${limit}&sortBy=createdAt:desc&name=${filterName}`;

  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/interview${filterName ? withsearchquery : query}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function deleteInterview(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .delete(`v1/interview/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function updateInterview(id, data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .patch(`v1/interview/${id}`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function interviewVideoAnalysis(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/interview/analysis', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function createInterview(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/interview', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function createNewInterview(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/interview/create', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function createInterviewVideo(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/interview-video', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function createNewInterviewVideo(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/interview-video/new', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function uploadInterviewVideo(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/interview-video/upload', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function uploadVideo(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/interview/video-upload', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// video upload url
export async function getVideoUploadURL(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/interview/video-upload-url?type=${data?.type}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getInterviewByID(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/interview/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function graphData(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post(`v1/interview/graph-data`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getQuestionAnswerInterviewByID(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/question-answer/interview/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getVideosByInterviewID(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/interview-video/interview/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getuploadingStatus(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/loading-status`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getQuestionStreams() {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/streams?limit=1000&page=1&type=active`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function addQuestionStream(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post(`v1/streams`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// get question answer list by id
// question
export async function getQuestionAnswerListById(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post(`v1/question-answer/by-question-type`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// admin apis

// get users
export async function getUsersList(page, limit, filterName) {
  let query = `?sortBy=createdAt:desc&page=${page}&limit=${limit}`;
  let withsearchquery = `?page=${page}&limit=${limit}&name=${filterName}`;

  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/admin/users${filterName ? withsearchquery : query}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getUserByID(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/admin/users/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function deleteUser(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .delete(`v1/admin/users/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function adminSignup(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/admin/users', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export async function adminUserUpdate(id, data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .patch(`v1/admin/users/${id}`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// question answer video
export async function getQuestionAnswerVideoList(page, limit, filter) {
  let query = `?page=${page}&limit=${limit}`;
  if (filter?.type) query = query + `&type=${filter?.type}`;
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/interview-video${query}&sortBy=createdAt:desc`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function deleteQuestionAnswerVideo(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .delete(`v1/interview-video/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function createScheduledInterview(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/scheduled-interview', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getScheduledInterviews(data) {
  let url = `v1/scheduled-interview?`;
  if (data?.page) url = url + `page=${data?.page}&`;
  if (data?.limit) url = url + `limit=${data?.limit}&`;
  if (data?.name) url = url + `name=${data?.name}&`;

  return new Promise((resolve, reject) => {
    axios_instance
      .get(url)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getScheduledInterview(id) {
  let url = `v1/scheduled-interview/${id}`;
  return new Promise((resolve, reject) => {
    axios_instance
      .get(url)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function updateScheduledInterview(id, data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .patch('v1/scheduled-interview/' + id, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function deleteScheduledInterview(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .delete(`v1/scheduled-interview/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function verifyEmailScheduledInterview(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/scheduled-interview/verify-candidate', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Create a new subscription (Admin only)
export async function createSubscription(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/subscriptions', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Get all subscriptions with optional filters
export async function getSubscriptionList(page, limit, filterName) {
  let query = `?page=${page}&limit=${limit}&sortBy=createdAt:desc&`;
  if (filterName) query = query + `name=${filterName}&`;

  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/subscriptions${query}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Get subscription by ID
export async function getSubscription(id) {
  let url = `v1/subscriptions/${id}`;
  return new Promise((resolve, reject) => {
    axios_instance
      .get(url)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Update subscription by ID (Admin only)
export async function updateSubscription(id, data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .patch(`v1/subscriptions/${id}`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Delete subscription by ID (Admin only)
export async function deleteSubscription(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .delete(`v1/subscriptions/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Assign subscription to user
export async function assignSubscription(userId, subscriptionId) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post(`v1/subscriptions/assign/${userId}`, { subscriptionId })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Get user's current subscription
export async function getUserSubscription(userId) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/subscriptions/user/${userId}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Cancel user's subscription
export async function cancelSubscription(subscriptionId) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post(`v1/subscriptions/${subscriptionId}/cancle`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Get subscription trial status
export async function getTrialStatus(subscriptionId) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/subscriptions/${subscriptionId}/trial`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Get subscription renewal information
export async function getRenewalInfo(subscriptionId) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/subscriptions/${subscriptionId}/renewal`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Update subscription by ID (Admin only)
export async function updateUserSubscription(id, data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .patch(`v1/subscriptions/user/${id}`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Create a new QuestionType
export async function createQuestionType(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/question-type', data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export async function getQuestionType(data) {
  return new Promise((resolve, reject) => {
    var url = `v1/question-type?type=active&`;
    if (data?.page) url = url + `page=${data?.page}&`;
    else url = url + `page=1&`;
    if (data?.limit) url = url + `limit=${data?.limit}&`;
    else url = url + `limit=10000&`;
    if (data?.stream_id) url = url + `stream=${data?.stream_id}&`;
    if (data?.name) url = url + `name=${data?.name}&`;

    axios_instance
      .get(url)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Get a specific QuestionType by ID
export async function getQuestionTypeByID(questionTypeId) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/question-type/${questionTypeId}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

// Update a specific QuestionType by ID
export async function updateQuestionType(questionTypeId, data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .patch(`v1/question-type/${questionTypeId}`, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

// Delete a specific QuestionType by ID
export async function deleteQuestionType(questionTypeId) {
  return new Promise((resolve, reject) => {
    axios_instance
      .delete(`v1/question-type/${questionTypeId}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

// Add these new functions to your service.jsx file

export async function createStream(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/streams', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getStreamsList(page, limit, filterName) {
  let query = `?page=${page}&limit=${limit}&sortBy=createdAt:desc`;
  let withsearchquery = `?page=${page}&limit=${limit}&sortBy=createdAt:desc&name=${filterName}`;

  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/streams${filterName ? withsearchquery : query}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getStreamById(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .get(`v1/streams/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function updateStream(id, data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .patch(`v1/streams/${id}`, data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function deleteStream(id) {
  return new Promise((resolve, reject) => {
    axios_instance
      .delete(`v1/streams/${id}`)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function cancelSubscriptionWithReason(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/subscriptions/cancle', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getAllStreams() {
  return new Promise((resolve, reject) => {
    axios_instance
      .get('v1/streams/get-all-streams')
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function requestDemo(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post('v1/demo', data)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
