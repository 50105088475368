import { useContext, useState } from "react";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { useRouter } from "src/routes/hooks";
import Iconify from "src/components/iconify";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, googleProvider } from "../../config/firebase";
import { signInWithPopup } from "firebase/auth";
import appContext from "src/Context/AppContext";
import {
  authorizeMe,
  getByUsername,
  googleAuth,
  me,
  signin,
} from "src/service/service";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// ----------------------------------------------------------------------

export default function LoginView() {
  const theme = useTheme();
  const router = useRouter();
  const [showPassword, setShowPassword] = useState(false);
  const AppContext = useContext(appContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const signInWithGoogle = async () => {
    AppContext?.setLoading(true);
    try {
      var res = await signInWithPopup(auth, googleProvider);
      if (res) {
        var obj = {
          email: res?.user?.email,
          name: res?.user?.displayName,
          googleld: res?.user?.uid,
          accessToken: res?.user?.accessToken,
          providerDetails: res?._tokenResponse,
        };
        var response = await googleAuth(obj);
        if (response) {
          AppContext?.setToken(response?.data?.tokens?.access?.token);
          localStorage.setItem("token", response?.data?.tokens?.access?.token);
          AppContext?.setUser(response?.data?.user);
          AppContext?.setIsLoggedIn(true);
          await authorizeMe(response?.data?.tokens?.access?.token);
          router.push("/");
          AppContext?.setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
      AppContext?.setLoading(false);
      AppContext?.setIsLoggedIn(true);
    }
  };

  const onSubmit = async (data) => {
    AppContext?.setLoading(true);

    try {
      // Initial login
      let res = await signin(data);

      if (!res?.data?.tokens?.access?.token) {
        throw new Error("Invalid response from server");
      }

      // Set token first
      AppContext?.setToken(res?.data?.tokens?.access?.token);
      localStorage.setItem("token", res?.data?.tokens?.access?.token);

      // Authorize for subsequent requests
      await authorizeMe(res?.data?.tokens?.access?.token);

      // Get complete user data
      const userResponse = await me();

      // Set complete user data including subscription
      AppContext?.setUser(userResponse?.data);
      AppContext?.setIsLoggedIn(true);

      // Only redirect after complete data is loaded
      router.push("/");
    } catch (e) {
      // Error handling...
      toast.error(e?.response?.data?.message);
    } finally {
      AppContext?.setLoading(false);
    }
  };

  const renderForm = (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          {...register("email", {
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Invalid email address",
            },
          })}
        />
        {errors.email && errors.email.type === "required" && (
          <p className="text-danger " style={{ fontSize: 12 }}>
            Email is required.
          </p>
        )}
        {errors.email && errors.email.type === "pattern" && (
          <p className="text-danger " style={{ fontSize: 12 }}>
            Invalid email
          </p>
        )}
        {errors.email && errors.email.type === "manual" && (
          <p className="text-danger " style={{ fontSize: 12 }}>
            {errors.email.message}
          </p>
        )}
        <TextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register("password", { required: true })}
        />
        {errors.password && errors.password.type === "required" && (
          <p className="text-danger " style={{ fontSize: 12 }}>
            Password is required.
          </p>
        )}
        {errors.password && errors.password.type === "manual" && (
          <p className="text-danger " style={{ fontSize: 12 }}>
            {errors.password.message}
          </p>
        )}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 3 }}
      >
        <Link
          variant="subtitle2"
          underline="hover"
          sx={{ cursor: "pointer" }}
          onClick={() => router.push("/forgot-password")}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
      >
        Sign In
      </LoadingButton>
    </form>
  );

  return (
    <>
      <Typography
        variant="h6"
        sx={{ mb: 4, fontWeight: 500, textAlign: "center" }}
      >
        Sign In{" "}
      </Typography>
      {renderForm}
      <Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
        Don’t have an account yet?
        <Link
          variant="subtitle2"
          sx={{ ml: 0.5, cursor: "pointer" }}
          onClick={() => {
            router.push("/register");
          }}
        >
          Sign Up
        </Link>
      </Typography>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          OR
        </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          sx={{
            borderColor: alpha(theme.palette.grey[500], 0.16),
            fontWeight: 500,
          }}
          onClick={signInWithGoogle}
        >
          <img
            src={"/assets/image/search.png"}
            width={18}
            height={18}
            style={{ marginRight: 8 }}
          />
          Sign in with Google
        </Button>
      </Stack>
    </>
  );
}
