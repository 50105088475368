import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import { useRouter } from "src/routes/hooks";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const router = useRouter();

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <RouterLink
          to="/"
          className="flexNullCenter"
          style={{ textDecoration: "none" }}
        >
          <img src={"/assets/image/logo.png"} width={35} />
          <Typography variant="h4" ml={2} className="whiteColor">
            Capstone
          </Typography>
        </RouterLink>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/"
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
            onClick={() => {
              toggleSidebar(!sidebarOpen);
              setTimeout(() => {
                const element = document.getElementById("home");
                if (element) {
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }, 100);
            }}
          >
            Home
          </RouterLink>
        </li>
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/"
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
            onClick={() => {
              toggleSidebar(!sidebarOpen);
              setTimeout(() => {
                const element = document.getElementById("about");
                if (element) {
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }, 100);
            }}
          >
            About Us
          </RouterLink>
        </li>
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/"
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
            onClick={() => {
              toggleSidebar(!sidebarOpen);
              setTimeout(() => {
                const element = document.getElementById("contact");
                if (element) {
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }, 100);
            }}
          >
            Contact Us
          </RouterLink>
        </li>
        <li className="semiBold font15 pointer">
          <RouterLink
            to="/pricing"
            className="whiteColor"
            style={{ padding: "10px 15px", textDecoration: "none" }}
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            Pricing
          </RouterLink>
        </li>
      </UlStyle>

      <UlStyleDemo className="flexCenter">
        <li className="semiBold font15 pointer">
          <LoadingButton
            variant="outlined"
            fullWidth
            onClick={() => {
              router.push("/demo");
              toggleSidebar(!sidebarOpen);
            }}
            sx={{
              color: "white",
              borderColor: "white",
              minWidth: "200px",
              marginBottom: "20px"
            }}
          >
            Request Demo
          </LoadingButton>
        </li>
      </UlStyleDemo>

      <UlStyleAuth className="flexSpaceCenter">
        <li className="semiBold font15 pointer" style={{ marginRight: 10 }}>
          <LoadingButton
            variant="outlined"
            onClick={() => {
              router.push("/login");
              toggleSidebar(!sidebarOpen);
            }}
            sx={{ color: "white", borderColor: "white" }}
          >
            Sign In
          </LoadingButton>
        </li>
        <li className="semiBold font15 pointer flexCenter">
          <LoadingButton
            variant="outlined"
            onClick={() => {
              router.push("/register");
              toggleSidebar(!sidebarOpen);
            }}
            sx={{ color: "white", borderColor: "white" }}
          >
            Sign Up
          </LoadingButton>
        </li>
      </UlStyleAuth>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
`;

const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;

const UlStyleDemo = styled.ul`
  padding: 0 40px;
`;

const UlStyleAuth = styled.ul`
  padding: 20px 40px 40px 40px;
`;