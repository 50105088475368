import React, { useContext, useEffect } from 'react';
// Sections
import TopNavbar from 'src/components/Nav/TopNavbar';
import Blog from 'src/sections/home/Blog';
import Contact from 'src/sections/home/Contact';
import Pricing from 'src/sections/home/Pricing';
import Projects from 'src/sections/home/Projects';
import Footer from 'src/sections/home/Footer';
import Services from 'src/sections/home/Services';
import 'src/style/flexboxgrid.min.css';
import 'src/style/index.css';
import Header from 'src/sections/home/Header';

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <Contact />
      <Footer />
    </>
  );
}
