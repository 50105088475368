// src/components/Nav/TopNavbar.jsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import Sidebar from '../Nav/Sidebar';
import Backdrop from '../Elements/Backdrop';
import LogoIcon from '../../assets/svg/Logo';
import BurgerIcon from '../../assets/svg/BurgerIcon';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useRouter, usePathname } from 'src/routes/hooks';
import { Link as RouterLink } from 'react-router-dom';

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    const handleScroll = () => {
      setY(window.scrollY);
      if (pathname === '/') {
        const sections = ['home', 'about', 'contact'];
        for (const section of sections) {
          const element = document.getElementById(section);
          if (element) {
            const rect = element.getBoundingClientRect();
            if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
              setActiveSection(section);
              break;
            }
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [y, pathname]);

  useEffect(() => {
    if (pathname === '/pricing') {
      setActiveSection('pricing');
    } else if (pathname === '/') {
      setActiveSection('home');
    } else {
      setActiveSection(null);
    }
  }, [pathname]);

  const handleDemoRequest = () => {
    navigate('/demo');
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: '60px' } : { height: '80px' }}
      >
        <NavInner className="container flexSpaceCenter">
          <RouterLink
            className="pointer flexNullCenter"
            to="/"
            smooth={true}
            spy={true}
          >
            <img src={'/assets/image/logo.png'} width={35} />
            <Typography variant="h4" ml={2}>
              Capstone
            </Typography>
          </RouterLink>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <StyledLink
                active={activeSection === 'home'}
                as={RouterLink}
                to="/"
                onClick={() => {
                  setActiveSection('home');
                  setTimeout(() => {
                    const element = document.getElementById('home');
                    if (element) {
                      element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }
                  }, 100);
                }}
              >
                Home
              </StyledLink>
            </li>
            <li className="semiBold font15 pointer">
              <StyledLink
                active={activeSection === 'about'}
                as={RouterLink}
                to="/"
                onClick={() => {
                  setActiveSection('about');
                  setTimeout(() => {
                    const element = document.getElementById('about');
                    if (element) {
                      element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }
                  }, 100);
                }}
              >
                About Us
              </StyledLink>
            </li>
            <li className="semiBold font15 pointer">
              <StyledLink
                active={activeSection === 'contact'}
                as={RouterLink}
                to="/"
                onClick={() => {
                  setActiveSection('contact');
                  setTimeout(() => {
                    const element = document.getElementById('contact');
                    if (element) {
                      element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }
                  }, 100);
                }}
              >
                Contact Us
              </StyledLink>
            </li>
            <li className="semiBold font15 pointer">
              <StyledLink
                active={activeSection === 'pricing'}
                as={RouterLink}
                to="/pricing"
                onClick={() => setActiveSection('pricing')}
              >
                Pricing
              </StyledLink>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer" style={{ marginRight: 10 }}>
              <LoadingButton
                variant="outlined"
                onClick={() => router.push('/demo')}
                color="secondary"
              >
                Request Demo
              </LoadingButton>
            </li>
            <li className="semiBold font15 pointer" style={{ marginRight: 10 }}>
              <LoadingButton
                variant="outlined"
                onClick={() => router.push('/login')}
              >
                Sign In
              </LoadingButton>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <LoadingButton
                variant="outlined"
                onClick={() => router.push('/register')}
              >
                Sign Up
              </LoadingButton>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`;

const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 912px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 912px) {
    display: none;
  }
`;

const UlWrapperRight = styled.ul`
  @media (max-width: 912px) {
    display: none;
  }
`;

const StyledLink = styled.a`
  padding: 10px 15px;
  position: relative;
  color: ${(props) => (props.active ? '#2d59eb' : 'inherit')};
  transition: color 0.3s ease-in-out;
  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 15px;
    right: 15px;
    height: 2px;
    background-color: #2d59eb;
    transform: scaleX(${(props) => (props.active ? 1 : 0)});
    transition: transform 0.3s ease-in-out;
  }
  &:hover {
    color: #2d59eb;
    &:after {
      transform: scaleX(1);
    }
  }
`;
