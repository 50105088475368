import React from 'react';
import styled from 'styled-components';
// Components
// Assets
import HeaderImage from '../../assets/img/BoyInterview.jpg';
import AddImage1 from '../../assets/img/role_to_youre_choice.avif';
import AddImage2 from '../../assets/img/AI_power.jpg';
import AddImage3 from '../../assets/img/time_saving2.jpg';
import AddImage4 from '../../assets/img/role_specific.jpg';
import AddImage5 from '../../assets/img/image-cp-1.jpg';
import AddImage6 from '../../assets/img/image-cp-2.jpg';
import AddImage7 from '../../assets/img/image-cp-3.jpg';
import AddImage8 from '../../assets/img/image-cp-5.jpg';
import AddImage9 from '../../assets/img/image-cp-4.jpg';
import AddImage10 from '../../assets/img/image-cp-6.jpg';

import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useRouter } from 'src/routes/hooks';
import { LoadingButton } from '@mui/lab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Services() {
  const [value, setValue] = React.useState(0);
  const router = useRouter();
  const mediaQueryList = window.matchMedia('(max-width: 700px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div style={{ background: '#F5F5F5' }}>
      <Wrapper id="about">
        <div
          className="lightBg"
          style={{ background: '#2f5190', position: 'relative' }}
        >
          <div className="context" style={{ zIndex: 2 }}>
            <div className="container">
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                textAlign={'center'}
              >
                <div className="flexCenter flexColumn">
                  <Typography variant="h3" color={'#fff'}>
                    Welcome to Capstone Interviewer
                  </Typography>
                  <Typography variant="h6" mb={5} color={'#fff'}>
                    Redefining the Future of Interviews{' '}
                  </Typography>

                  {/* <video width={!mediaQueryList?.matches ? "600" : "auto"} controls src="https://www.youtube.com/watch?v=JEAWDE-rnN8" style={{ borderRadius: 15 }} >

                </video> */}
                  <p style={{ color: 'white' }}>
                    Capstone Interviewer is an innovative dual-sided platform
                    designed to meet the evolving needs of today's job seekers
                    and hiring managers. Powered by cutting-edge AI technology
                    and driven by the goal of creating seamless connections
                    between talent and opportunity, our platform transforms the
                    way interviews are prepared for, conducted, and evaluated.
                  </p>
                  <p style={{ color: 'white', margin: '25px 0px' }}>
                    Whether you’re a job seeker aiming to secure your dream role
                    or a hiring manager focused on building the best team,
                    Capstone Interviewer empowers you with the tools and
                    insights to succeed.
                  </p>
                  <p style={{ color: 'white' }}>
                    Our mission is simple yet powerful: to make interviews
                    smarter, faster, and more effective for everyone. With
                    customized solutions, automated processes, and actionable
                    feedback, we ensure that every interaction is meaningful,
                    efficient, and impactful.
                  </p>

                  <BtnWrapper style={{ marginTop: '24px', zIndex: 10 }}>
                    <LoadingButton
                      variant="contained"
                      onClick={() => router.push('/register')}
                    >
                      Try Capstone Interviewer
                    </LoadingButton>
                  </BtnWrapper>
                </div>
              </Box>
            </div>
          </div>
          <div className="area" style={{ zIndex: 1 }}>
            <ul className="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className="lightBg" style={{ padding: '60px 0' }}>
          <div className="whiteBg">
            <div className="container">
              <Advertising1 className="flexSpaceCenter">
                <AddLeft1>
                  <Typography variant="h3">For Job Seekers</Typography>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    sx={{
                      fontSize: {
                        xs: '15px',
                        sm: '17px',
                      },
                    }}
                  >
                    Landing your dream job takes more than a great resume—it
                    requires confidence and strategy. Capstone Interviewer helps
                    you stand out with:
                  </Typography>
                  <p
                    style={{ marginTop: '10px', font: '10px' }}
                    className="responsive-para"
                  >
                    <ul>
                      <li>
                        <strong>Unlimited Feedback : </strong> Get AI-powered
                        and human-reviewed feedback for any role or industry.
                      </li>
                      <li>
                        <strong>AI Skill Analysis : </strong> Improve speaking,
                        confidence, and body language with detailed insights.
                      </li>
                      <li>
                        <strong>Realistic Simulations : </strong> Practice
                        role-specific interviews to prepare for industry
                        challenges.
                      </li>
                      <li>
                        <strong>Flexible Preparation : </strong> Practice
                        anytime, saving hours compared to traditional coaching.
                      </li>
                    </ul>
                  </p>
                  <BtnWrapper style={{ marginTop: '1rem', maxWidth: '100%' }}>
                    <LoadingButton
                      variant="contained"
                      onClick={() => router.push('/register?type=jobseeker')}
                    >
                      Try Capstone Interviewer
                    </LoadingButton>
                  </BtnWrapper>
                </AddLeft1>

                <VideoRight>
                  <VideoContainer2>
                    <Video2 autoPlay muted loop playsInline>
                      <source
                        src="https://capstone-interview.s3.ap-south-1.amazonaws.com/1737120174112-capstone-interview.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </Video2>
                  </VideoContainer2>
                </VideoRight>
              </Advertising1>
            </div>
          </div>

          <div className="whiteBg landingPageHiringPara">
            <div className="container">
              <Advertising2 className="flexSpaceCenter">
                <AddLeft2>
                  <Typography variant="h3">For Hiring Managers </Typography>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    sx={{
                      fontSize: {
                        xs: '15px',
                        sm: '17px',
                      },
                    }}
                  >
                    Capstone Interviewer streamlines hiring, saving time and
                    improving candidate selection with:
                  </Typography>
                  <p style={{ marginTop: '10px' }} className="responsive-para">
                    <ul>
                      <li>
                        <strong>Automated Screening : </strong> Cut early-round
                        evaluations by 70% with AI analysis.
                      </li>
                      <li>
                        <strong>Customizable Criteria : </strong> Tailor
                        screening to fit your organization's needs.
                      </li>
                      <li>
                        <strong>Data-Driven Reports : </strong> Get clear
                        candidate insights, reducing follow-ups by 50%.
                      </li>
                      <li>
                        <strong>Faster Hiring : </strong> Shorten hiring
                        timelines by two weeks or more.
                      </li>
                      <li>
                        <strong>Cost Savings : </strong> Automate tasks and cut
                        early-stage interview costs by 30%.
                      </li>
                    </ul>
                  </p>
                  <BtnWrapper style={{ marginTop: '1rem', maxWidth: '100%' }}>
                    <LoadingButton
                      variant="contained"
                      onClick={() => router.push('/register?type=interviewer')}
                    >
                      Try Capstone Interviewer
                    </LoadingButton>
                  </BtnWrapper>
                </AddLeft2>

                <VideoRight>
                  <VideoContainer2>
                    <Video2 autoPlay muted loop playsInline>
                      <source
                        src="https://capstone-interview.s3.ap-south-1.amazonaws.com/1737120296816-capstone-interview.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </Video2>
                  </VideoContainer2>
                </VideoRight>
              </Advertising2>
            </div>
          </div>

          <div className="container tabsforWhy">
            <Box
              sx={{
                py: 8,
                px: { xs: 2, sm: 4, md: 6 },
                backgroundColor: 'white',
                borderRadius: 2,
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                pb={5}
              >
                <Typography variant="h3" mb={1} color="#000" textAlign="center">
                  Why Capstone Interviewer?
                </Typography>
              </Box>

              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="platform features"
                    centered
                  >
                    <Tab
                      label="For Job Seekers"
                      {...a11yProps(0)}
                      sx={{ fontSize: { sm: '16px', md: '1rem' } }}
                    />
                    <Tab
                      label="For Hiring Managers"
                      {...a11yProps(1)}
                      sx={{ fontSize: { sm: '16px', md: '1rem' } }}
                    />
                  </Tabs>
                </Box>

                <div className="background-4">
                  <CustomTabPanel value={value} index={0}>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={12}>
                        <Box sx={{ p: { xs: 2, md: 4 } }}>
                          <p style={{ padding: '20px' }}>
                            Build Confidence, Save Time, and Prepare Effectively
                            for Any Interview with Capstone Interviewer, where
                            we help you build the confidence to succeed in any
                            interview. Whether you're facing a technical
                            challenge, a behavioral question, or a role-specific
                            simulation, our platform empowers you with the tools
                            to shine. Gain valuable insights into your speaking
                            skills, confidence, and body language through
                            AI-powered feedback and real-world practice.
                          </p>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              mt: 2,
                            }}
                          >
                            <LoadingButton
                              variant="contained"
                              onClick={() =>
                                router.push('/register?type=jobseeker')
                              }
                            >
                              Try Capstone Interviewer
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={1}>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={12}>
                        <Box sx={{ p: { xs: 2, md: 4 } }}>
                          <p style={{ padding: '20px' }}>
                            Streamline Hiring, Reduce Time-to-Decision, and Make
                            Data-Backed Talent Choices. Our platform helps you
                            efficiently screen candidates, tailor evaluation
                            criteria to your specific needs, and gain actionable
                            data on each applicant's strengths and potential
                            fit. Reduce time-to-hire and ensure you're selecting
                            the best talent by relying on data-backed, objective
                            assessments that enhance your hiring process.
                          </p>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              mt: 2,
                            }}
                          >
                            <LoadingButton
                              variant="contained"
                              onClick={() =>
                                router.push('/register?type=interviewer')
                              }
                            >
                              Try Capstone Interviewer
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                </div>
              </Box>
            </Box>
          </div>
        </div>
      </Wrapper>
      <div
        className="container override flexCenter"
        style={{
          padding: '90px 20px 90px 20px',
          background: 'white',
          width: '100%',
        }}
      >
        <HeaderInfo
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '1220px',
          }}
        >
          <h2 className="font40 ssss extraBold">
            Let us help you achieve your goals
          </h2>
          <div className="flexNullCenter pretext">
            {/* <h3> start your journey today with </h3> */}
            <span>
              <BtnWrapper>
                <LoadingButton
                  variant="contained"
                  onClick={() => router.push('/register')}
                >
                  Try Capstone Interviewer
                </LoadingButton>
              </BtnWrapper>
            </span>
          </div>
          <br />
          <br />
          <p
            className="innerContent"
            style={{ textAlign: 'center', maxWidth: '100%' }}
          >
            {' '}
            Whether you’re on the hunt for your ideal job or looking to build a
            top-tier team, Capstone Interviewer is here to streamline your
            journey. We’re committed to making the hiring and job-search process
            as seamless and efficient as possible, empowering both job seekers
            and hiring managers to reach their goals with confidence. For job
            seekers, we provide the tools to help you refine your interview
            skills, boost your confidence, and land your dream role. For hiring
            managers, we offer AI-driven solutions to accelerate candidate
            screening, improve hiring accuracy, and reduce costs. With Capstone
            Interviewer, you gain access to innovative tools that transform how
            you prepare, evaluate, and select the best talent. Let us be your
            partner in success. Together, we’ll turn your goals into
            achievements.
          </p>
        </HeaderInfo>
      </div>

      {/* <div className="container " style={{ padding: "60px 20px 0px 20px", background: 'white' }}>
        <HeaderInfo>
          <Typography variant="h3" color={'#000'} textAlign={'center'}>Our Product</Typography>
          <br />
          <br />
          <p>  Welcome to Capstone Interviewer, your ultimate solution for medical school interview preparation. Our innovative tool is designed to provide you with a comprehensive and immersive interview practice experience, ensuring you are well-prepared to excel in your medical school interviews.</p>
        </HeaderInfo>
        <ServiceBoxRow className="flex home-tabs" style={{ display: "flex", justifyContent: "center", padding: '0px 100px' }}>
          <ServiceBoxWrapper style={{ justifyContent: 'center' }}>
            <ServiceBox
              icon="roller"
              title="Tailored Interview Simulation"
              subtitle="Experience realistic simulations of medical school interviews with a large pool of commonly asked questions."
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="monitor"
              title="Personalized Feedback"
              subtitle="Receive crucial feedback on your verbal and non-verbal communication skills, enhancing your overall interview performance"
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox
              icon="browser"
              title="Advanced Analysis"
              subtitle="Benefit from advanced algorithms that analyze facial expressions, sentiments, and speech quality, providing real-time feedback for improvement."
            />
          </ServiceBoxWrapper>
          <ServiceBoxWrapper>
            <ServiceBox icon="printer" title="Optimal Answer Comparison" subtitle="Compare your responses with optimal answers through side-by-side comparisons, highlighting strengths and suggesting improvements." />
          </ServiceBoxWrapper>

        </ServiceBoxRow>
      </div> */}
    </div>
  );
}

const BtnWrapper = styled.div`
  max-width: 210px;

  /* @media (max-width: 960px) {
    margin: 0 auto;
  } */
`;

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising2 = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 100px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 80px 0;
  }
`;
const Advertising1 = styled.div`
  margin: 80px 0;
  padding: 120px 0;
  position: relative;
  flex-direction: row-reverse;
  @media (max-width: 1160px) {
    padding: 100px 0 100px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft1 = styled.div`
  width: 60%;
  padding-left: 60px;
  /* text-align: end; */
  p {
    max-width: 100%;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    padding-left: 0px;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddLeft2 = styled.div`
  width: 60%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;

    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 40%;
  position: absolute;
  /* top: -70px; */
  right: 0;
  @media (max-width: 860px) {
    width: 60%;
    /* height: 50%; */
    position: relative;
    order: 1;
    top: -40px;
  }
  @media (max-width: 450px) {
    width: 100%;
    /* height: 50%; */
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddLeftImg = styled.div`
  width: 40%;
  position: absolute;
  /* top: -70px; */
  left: 0;
  /* padding-right: 25px; */
  @media (max-width: 860px) {
    width: 60%;
    position: relative;
    order: 1;
    top: -40px;
    padding-right: 0px;
  }
  @media (max-width: 450px) {
    width: 100%;
    position: relative;
    order: 1;
    top: -40px;
    padding-right: 0px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 90%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 50%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 50%;
  margin-left: 10%;
  margin-top: 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 50%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const Img = styled.img`
  @media (max-width: 560px) {
    width: 30%;
    height: 30%;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 800px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 960px) {
    margin: 0 auto;
    max-width: 100%;
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  /* min-height: 400px; */
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const VideoRight = styled.div`
  width: 40%;
  position: relative;
  @media (max-width: 860px) {
    width: 80%;
    order: 1;
    margin-bottom: 30px;
  }
`;

const VideoContainer2 = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
`;

const Video2 = styled.video`
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 8px;
`;
