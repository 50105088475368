import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Container,
  useTheme,
  useMediaQuery,
  Chip,
} from '@mui/material';
import {
  Check,
  Person,
  Business,
  StarBorder,
  Groups,
} from '@mui/icons-material';
import TopNavbar from 'src/components/Nav/TopNavbar';
import Contact from 'src/sections/home/Footer.jsx';
import { useRouter } from 'src/routes/hooks';
import './pricing.css';

export default function Pricing() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();

  const jobSeekerFeatures = [
    'Unlimited AI-powered mock interviews',
    'Detailed feedback on confidence, pacing, and response quality',
    'Real-time performance analytics',
    'Access to diverse question bank',
    'Personalized improvement recommendations',
    '24/7 practice availability',
  ];

  const interviewerFeatures = [
    'Schedule and manage unlimited interviews',
    'AI-powered candidate evaluation',
    'Comprehensive analytics dashboard',
    'Custom question bank management',
    'Team collaboration tools',
    'Advanced reporting features',
    'Video recording and playback',
    'Candidate performance tracking',
  ];

  const enterpriseFeatures = [
    'All Interviewer Plan features',
    'Unlimited team members',
    'Custom branding options',
    'API access',
    'Dedicated account manager',
    'Premium support',
    'Custom integrations',
    'Advanced security features',
    'Data export capabilities',
    'SLA guarantees',
  ];

  const plans = [
    {
      name: 'Job Seeker Plan',
      price: 49,
      duration: 30,
      trialPeriod: 3,
      features: jobSeekerFeatures,
      icon: <Person />,
      type: 'jobseeker',
      description: 'Perfect for candidates preparing for interviews',
      isPremium: false,
    },
    {
      name: 'Interviewer Plan',
      price: 2500,
      duration: 30,
      trialPeriod: 0,
      features: interviewerFeatures,
      icon: <Business />,
      type: 'interviewer',
      description: 'Ideal for hiring managers and recruiters',
      isPremium: true,
    },
    {
      name: 'Enterprise Plan',
      price: 'Custom',
      customPrice: true,
      features: enterpriseFeatures,
      icon: <Groups />,
      type: 'enterprise',
      description: 'Tailored solutions for large organizations',
      isPremium: false,
    },
  ];

  const handleSelect = (type) => {
    if (type === 'enterprise') {
      window.location.href = 'https://calendly.com/shawn-gregoire/30min';
    } else {
      router.push(`/register?type=${type}`);
    }
  };

  return (
    <>
      <TopNavbar />
      <Container maxWidth="lg" sx={{ py: 13 }}>
        <Box textAlign="center" mb={{ xs: 6, md: 8 }}>
          <Typography
            variant="h3"
            component="h1"
            fontWeight="bold"
            color="primary"
            mb={2}
          >
            Choose Your Plan
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ maxWidth: '800px', mx: 'auto' }}
          >
            Whether you're preparing for interviews or hiring talent, we have
            the perfect plan for you
          </Typography>
        </Box>

        <Grid container spacing={4} justifyContent="center">
          {plans.map((plan) => (
            <Grid item xs={12} md={4} key={plan.name}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  p: { xs: 2, md: 3 },
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: theme.shadows[10],
                  },
                  ...(plan.isPremium && {
                    border: `2px solid ${theme.palette.primary.main}`,
                  }),
                }}
              >
                <Stack spacing={3} flex={1}>
                  <Box>
                    {plan.trialPeriod > 0 && (
                      <Chip
                        icon={<StarBorder />}
                        label={`${plan.trialPeriod}-Day Free Trial`}
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{ mb: 2 }}
                      />
                    )}
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                    >
                      {plan.name}
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      spacing={1}
                      mb={2}
                    >
                      {plan.customPrice ? (
                        <Typography
                          variant="h4"
                          color="primary"
                          fontWeight="bold"
                        >
                          {plan.price}
                        </Typography>
                      ) : (
                        <>
                          <Typography
                            variant="h4"
                            color="primary"
                            fontWeight="bold"
                          >
                            ${plan.price}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                          >
                            /{plan.duration} days
                          </Typography>
                        </>
                      )}
                    </Stack>
                    <Typography variant="body2" color="text.secondary" mb={3}>
                      {plan.description}
                    </Typography>
                  </Box>

                  <Stack spacing={2} flex={1}>
                    {plan.features.map((feature, index) => (
                      <Stack
                        direction="row"
                        spacing={1.5}
                        alignItems="center"
                        key={index}
                      >
                        <Check color="success" sx={{ fontSize: 20 }} />
                        <Typography variant="body1">{feature}</Typography>
                      </Stack>
                    ))}
                  </Stack>

                  <Button
                    variant={plan.isPremium ? 'contained' : 'outlined'}
                    size={isMobile ? 'large' : 'medium'}
                    fullWidth
                    onClick={() => handleSelect(plan.type)}
                    sx={{
                      mt: 'auto',
                      py: 1.5,
                      textTransform: 'none',
                      fontWeight: 600,
                    }}
                  >
                    {plan.type === 'enterprise'
                      ? 'Request a Demo'
                      : plan.isPremium
                        ? 'Start Premium Trial'
                        : 'Get Started'}
                  </Button>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box mt={8} textAlign="center">
          <Typography variant="h5" fontWeight="bold" mb={2}>
            If you've already subscribed, hold tight!
          </Typography>
          <Typography variant="body1" color="text.secondary" mb={2}>
            You'll soon gain access to the exciting features of Capstone
            Interviewer. Stay tuned—great things are on the way!
          </Typography>
        </Box>

        <Box mt={8} textAlign="center">
          <Typography variant="body1" color="text.secondary">
            Questions? Contact our support team at{' '}
            <a
              href="mailto:shawn@capstoneinterviewer.com"
              style={{ color: theme.palette.primary.main }}
            >
              shawn@capstoneinterviewer.com
            </a>
          </Typography>
        </Box>
      </Container>
      <Contact />
    </>
  );
}
