import { Box, Card, Stack } from '@mui/material';
import { lazy, Suspense, useContext } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';
import appContext from 'src/Context/AppContext';
import DashboardLayout from 'src/layouts/dashboard';
import ResetPasswordPage from 'src/pages/public/resetpassword';

export const IndexPage = lazy(() => import('src/pages/private/app'));
export const InterviewPage = lazy(() => import('src/pages/private/interview'));
export const UploadPage = lazy(() => import('src/pages/private/upload'));
export const LoginPage = lazy(() => import('src/pages/public/login'));
export const CandidateInterviewViewPage = lazy(() =>
  import('src/pages/public/candidate-interview'),
);
export const Landing = lazy(() => import('src/pages/public/Landing'));
export const RegisterPage = lazy(() => import('src/pages/public/register'));
export const HelpPage = lazy(() => import('src/pages/private/help'));
export const ForgotPasswordPage = lazy(() =>
  import('src/pages/public/forgotpassword'),
);
export const SettingsPage = lazy(() => import('src/pages/private/settings'));
export const LibraryPage = lazy(() => import('src/pages/private/library'));
export const ScheduledInterviewPage = lazy(() =>
  import('src/pages/private/scheduled-interview'),
);
export const ScheduledNewInterviewPage = lazy(() =>
  import('src/pages/private/schedule-new-interview'),
);
export const ViewAnalysisPage = lazy(() =>
  import('src/pages/private/viewAnalysis'),
);
export const AddUpdateUserPage = lazy(() =>
  import('src/pages/private/admin/addUpdateUser'),
);
export const AddUpdateSubscriptionPage = lazy(() =>
  import('src/pages/private/admin/addUpdateSubscription'),
);
export const Page404 = lazy(() => import('src/pages/private/page-not-found'));
export const Subscriptions = lazy(() =>
  import('src/pages/private/subscriptions'),
);
export const AdminDashboard = lazy(() =>
  import('src/pages/private/admin/adminDashboard'),
);
export const UsersPage = lazy(() => import('src/pages/private/admin/users'));
export const SubscriptionsPage = lazy(() =>
  import('src/pages/private/admin/subscription'),
);
export const QuestionAnswerPage = lazy(() =>
  import('src/pages/private/admin/questionAnswer'),
);
export const CategoryPage = lazy(() =>
  import('src/pages/private/admin/category'),
);

export const StreamPage = lazy(() => import('src/pages/private/admin/stream'));
export const QuestionAnswerVideoPage = lazy(() =>
  import('src/pages/private/questionAnswerVideo'),
);
export const PricingPage = lazy(() => import('src/sections/home/Pricing'));
export const PrivacyPolicy = lazy(() =>
  import('src/sections/home/PrivacyPolicy'),
);

export const PromptPage = lazy(() => import('src/pages/private/admin/prompt'));

export const DemoPage = lazy(() => import('src/pages/public/Demo'));

import { bgGradient } from 'src/theme/css';
import { alpha, useTheme } from '@mui/material/styles';
import Logo from 'src/components/logo';
import GuestGuard from './GuestGuard';
import AuthGuard from './AuthGuard';
import AuthGuardAdmin from './AuthGuardAdmin';

// ----------------------------------------------------------------------

export default function Router() {
  const AppContext = useContext(appContext);
  const theme = useTheme();
  var currentUser = localStorage.getItem('currentUser');
  if (currentUser) currentUser = JSON.parse(currentUser);
  var usersRoutes = [];

  if (currentUser?.role !== 'admin') {
    if (currentUser?.user_type === 'job seeker') {
      usersRoutes = [
        {
          element: (
            <AuthGuard>
              <DashboardLayout>
                <Suspense>
                  <Outlet />
                </Suspense>
              </DashboardLayout>
            </AuthGuard>
          ),
          children: [
            { element: <IndexPage />, index: true },
            { path: 'interview', element: <InterviewPage /> },
            { path: 'upload', element: <UploadPage /> },
            { path: 'settings', element: <SettingsPage /> },
            { path: 'library', element: <LibraryPage /> },
            {
              path: 'question-answer-video',
              element: <QuestionAnswerVideoPage />,
            },
            { path: 'help', element: <HelpPage /> },
            { path: 'view-analysis/:id', element: <ViewAnalysisPage /> },
          ],
        },
      ];
    } else {
      usersRoutes = [
        {
          element: (
            <AuthGuard>
              <DashboardLayout>
                <Suspense>
                  <Outlet />
                </Suspense>
              </DashboardLayout>
            </AuthGuard>
          ),
          children: !!currentUser?.subscription?.id
            ? [
                { element: <ScheduledInterviewPage />, index: true },
                {
                  path: 'scheduled-interview',
                  element: <ScheduledInterviewPage />,
                },
                { path: 'settings', element: <SettingsPage /> },
                {
                  path: 'schedule-new-interview',
                  element: <ScheduledNewInterviewPage />,
                },
                { path: 'help', element: <HelpPage /> },
                { path: 'view-analysis/:id', element: <ViewAnalysisPage /> },
              ]
            : [{ element: <ScheduledInterviewPage />, index: true }],
        },
      ];
    }
  } else {
    usersRoutes = [
      {
        element: (
          <AuthGuardAdmin>
            <DashboardLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          </AuthGuardAdmin>
        ),
        children: [
          { element: <UsersPage />, index: true },
          { path: 'user/:id', element: <AddUpdateUserPage /> },
          { path: 'user', element: <AddUpdateUserPage /> },

          {
            path: 'subscriptions',
            element: <SubscriptionsPage />,
            index: true,
          },
          { path: 'subscription/:id', element: <AddUpdateSubscriptionPage /> },
          { path: 'subscription', element: <AddUpdateSubscriptionPage /> },

          { path: 'prompt', element: <PromptPage /> },
          { path: 'question-answer', element: <QuestionAnswerPage /> },
          { path: 'streams', element: <StreamPage /> },
          { path: 'categorys', element: <CategoryPage /> },
        ],
      },
    ];
  }

  const routes = useRoutes([
    ...usersRoutes,

    {
      element: (
        <GuestGuard>
          <Box
            sx={{
              ...bgGradient({
                color: alpha(theme.palette.background.default, 0.9),
                imgUrl: '/assets/background/overlay_4.jpg',
              }),
              overflow: 'auto',
              display: 'flex',
              minHeight: '100vh',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px',
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '100%',
                maxHeight: '100%',
                padding: '20px',
              }}
            >
              <Logo />
              <Card
                sx={{
                  p: 4,
                  width: 1,
                  maxWidth: 500,
                  height: 'auto',
                }}
              >
                <Outlet />
              </Card>
            </Stack>
          </Box>
        </GuestGuard>
      ),
      children: [
        { path: 'login', element: <LoginPage /> },
        { path: 'register', element: <RegisterPage /> },
        { path: 'forgot-password', element: <ForgotPasswordPage /> },
        { path: 'reset-password', element: <ResetPasswordPage /> },
        // { path: "pricing", element: <PricingPage /> },
      ],
    },
    {
      path: '/candidate-interview/:interview_id',
      element: <CandidateInterviewViewPage />,
    },

    { element: <Landing />, index: true },
    { path: '404', element: <Page404 /> },
    { path: 'demo', element: <DemoPage /> },
    {
      path: 'subscriptions',
      element: (
        <AuthGuard>
          <Subscriptions />
        </AuthGuard>
      ),
    },
    {
      path: 'pricing',
      element: <PricingPage />,
    },
    { path: 'privacypolicy', element: <PrivacyPolicy /> },
    { path: '*', element: <Page404 /> },
  ]);
  return routes;
}
