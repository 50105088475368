import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import "../../vk.css";
import { LoadingButton } from "@mui/lab";
import { useRouter } from "src/routes/hooks";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const router = useRouter();

  return (
    <Wrapper>
      <div className="purpleBgGradient">
        <div className="container">
          <InnerWrapper
            className="flexCenter flexColumn"
            style={{ padding: "30px 0 10px 0" }}
          >
            <div className="flexSpaceCenter x100 ttCol">
              <RouterLink
                className="flexCenter animate pointer"
                to="/"
                onClick={() => {
                  setTimeout(() => {
                    const element = document.getElementById("home");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }, 100);
                }}
              >
                <div
                  style={{
                    background: "#fff",
                    padding: "8px 12px",
                    borderRadius: "50%",
                  }}
                >
                  <img src={"/assets/image/logo.png"} width={35} alt="logo" />
                </div>
                <h1
                  className="font15 extraBold whiteColor"
                  style={{ marginLeft: "15px" }}
                >
                  Capstone Interviewer
                </h1>
              </RouterLink>
              <div className="inner-container">
                <RouterLink
                  className="whiteColor animate pointer font13"
                  to="/"
                  onClick={() => {
                    setTimeout(() => {
                      const element = document.getElementById("home");
                      if (element) {
                        element.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }
                    }, 100);
                  }}
                >
                  Home
                </RouterLink>
                <RouterLink
                  className="whiteColor animate pointer font13"
                  to="/"
                  onClick={() => {
                    setTimeout(() => {
                      const element = document.getElementById("about");
                      if (element) {
                        element.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }
                    }, 100);
                  }}
                >
                  About Us
                </RouterLink>
                <RouterLink
                  className="whiteColor animate pointer font13"
                  to="/"
                  onClick={() => {
                    setTimeout(() => {
                      const element = document.getElementById("contact");
                      if (element) {
                        element.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }
                    }, 100);
                  }}
                >
                  Contact Us
                </RouterLink>
                <RouterLink
                  className="whiteColor animate pointer font13"
                  to="/privacypolicy"
                >
                  Privacy Policy
                </RouterLink>
              </div>
              <div className="btnGrp">
                <BtnWrapper>
                  <LoadingButton
                    variant="contained"
                    onClick={() => router.push("/register")}
                  >
                    Try Capstone Interviewer
                  </LoadingButton>
                </BtnWrapper>
              </div>
            </div>

            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <b className="font13">Capstone</b> All
              Right Reserved
            </StyleP>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 915px) {
    margin: 20px 0;
  }
`;
const BtnWrapper = styled.div`
  max-width: 210px;
`;