import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const jobSeekerNavConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'practice',
    icon: icon('video-plus'),
    items: [
      {
        title: 'Interview',
        path: '/interview',
        icon: icon('question-answer'),
      },
      {
        title: 'Uploads',
        path: '/upload',
        icon: icon('upload'),
      }
    ]
  },
  {
    title: 'QA Videos',
    path: '/question-answer-video',
    icon: icon('question-answer'),
  },
  {
    title: 'Library',
    path: '/library',
    icon: icon('video'),
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: icon('setting'),
  },
];
const interviewerNavConfig = [
  {
    title: 'Scheduled Interview',
    path: '/scheduled-interview',
    icon: icon('video'),
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: icon('setting'),
  },
];
const adminNavConfig = [
  {
    title: 'Users',
    path: '/',
    icon: icon('users'),
  },
  {
    title: 'Questions',
    path: '/question-answer',
    icon: icon('question-answer'),
  },
  {
    title: 'Streams',
    path: '/streams',
    icon: icon('ic_stream')
  },
  {
    title: 'Category',
    path: '/categorys',
    icon: icon('ic_category'),
  },
  {
    title: 'Subscription',
    path: '/subscriptions',
    icon: icon('ic_cart'),
  },
  {
    title: 'Prompt',
    path: '/prompt',
    icon: icon('prompt'),
  },
];

export { jobSeekerNavConfig, interviewerNavConfig, adminNavConfig };

